import Form from 'react-bootstrap/Form';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../lib/helper/form';
import { requests } from '../../lib/helper/agent';
import * as API_ROUTES from '../../lib/routing/api';
import { addToast } from '../../lib/redux/actions/toast';

const mapStateToProps = (state, props) => {
  return {
    form: props.form,
    initialValues: {
      recipient: props.paymentRequest?.email,
    },
  };
};

const mapDispatchToProps = {
  addToast,
};

class EmailPaymentReceiptForm extends React.Component {
  onSubmit(values) {
    const { addToast, paymentRequest, onPaymentRequestSendSuccess } = this.props;

    requests.post(API_ROUTES.PAYMENT_RECEIPT_EMAIL.replace(':paymentRequestId', paymentRequest?.id), values).then(() => {
      addToast('Successfully scheduled payment receipt.', true);
      onPaymentRequestSendSuccess(paymentRequest);
    }).catch(error => {
      addToast('Failed to schedule email receipt. Please try again later.', false, true);
      onPaymentRequestSendSuccess(paymentRequest);
    });
  }

  render() {
    const { handleSubmit, submitting, error, cancelSend } = this.props;
    return (
        <>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="d-flex justify-content-center px-2 alert border-success">
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="d-flex align-items-center">
              <div className="mr-3 pt-3">
                <Field
                    name="recipient"
                    aria-label="Recipient"
                    id="recipient"
                    type="email"
                    component={renderField}
                    horizontalLabelWidth={6}
                    horizontal={true}
                    label="Email Payment Receipt to:"
                />
              </div>
              <div className="col-auto">
                <button type="submit" disabled={submitting} className="btn btn-primary">
                  Send
                </button>
              </div>
              <div className="col-auto">
                <button type="button" className="btn btn-info ml-2" onClick={() => cancelSend()}>Cancel</button>
              </div>
            </Form>
          </div>
        </>

    );
  }
}

EmailPaymentReceiptForm.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      enableReinitialize: true,
    })(EmailPaymentReceiptForm),
);
