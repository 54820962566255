import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Step1Card } from './components/Step1Card';
import { Step2Card } from './components/Step2Card';
import { extractFollowingUntilByKeys, extractFollowingXByKey, extractWhereKeyContains } from '../../lib/helper/parsing';
import { requests } from '../../lib/helper/agent';
import * as API_ROUTES from '../../lib/routing/api';
import moment from 'moment';
import { Step3Card } from './components/Step3Card';
import { addToast } from '../../lib/redux/actions/toast';
import { Link } from 'react-router-dom';
import { INVOICES } from '../../lib/routing/frontend';
import { connect } from 'react-redux';
import { ContactRecordTypeCodes } from '../../apiTypes';
import { contactRecordTypeListFetch } from '../../lib/redux/actions/contactRecordType';
import Alert from 'react-bootstrap/Alert';
import { invoiceBatchSingleFetch } from '../../lib/redux/actions/invoiceBatch';

const defaultState = {
  extractedText: '',
  invoiceData: {},
  currentStep: 1,
  file: undefined,
  rawFile: undefined,
  pageNumber: 1,
  numPages: undefined,
  contacts: [],
  existingInvoiceId: false,
  isEditingContact: false,
};

const debug = false;

const mapStateToProps = (state) => ({
  ...state.contactRecordTypes,
});

const mapDispatchToProps = {
  addToast,
  contactRecordTypeListFetch,
  invoiceBatchSingleFetch,
};

class InvoiceParse extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    this.props.contactRecordTypeListFetch();
  }

  render() {
    const { addToast, isEmbed = false, invoiceBatch = undefined, contactRecordTypes = [] } = this.props;
    const setFile = (f) => this.setState({ file: f });
    const setRawFile = (f) => this.setState({ rawFile: f });
    const setIsEditingContact = (x) => this.setState({ isEditingContact: x });
    const setExtractedText = async (extractedText) => {
      const shortfallInvoiceDate = extractFollowingXByKey(extractedText, 'Date:');
      const shortfallAmountStr = extractWhereKeyContains(extractedText, 'Re. Insurance cover shortfall of');
      const invoiceNo = extractFollowingXByKey(extractedText, 'Invoice No:');
      const invoiceDate = extractFollowingXByKey(extractedText, 'Invoice Date:');
      const subscriberNumber = extractFollowingXByKey(extractedText, 'Subscriber #:');
      const claimNumber = extractFollowingXByKey(extractedText, 'Claim #:');
      const patientName = extractFollowingUntilByKeys(extractedText, 'Patient Name:', 'DOB:');
      const patientDOB = extractFollowingXByKey(extractedText, 'DOB:', 1);
      const invoiceAmount = extractFollowingXByKey(extractedText, 'Net Invoice:');
      const amountDue = extractFollowingXByKey(extractedText, 'Amount Due:');
      const serviceDate = extractFollowingXByKey(extractedText, 'Fee');
      const specialist = extractFollowingXByKey(extractedText, 'Specialist:');
      //      const address = extractFollowingUntilByKeys(extractedText, 'HA1 3EX', 'Invoice No:');
      const isShortfall = extractedText.indexOf('shortfall') > -1;

      let shortfallAmount = null;
      if (isShortfall && shortfallAmountStr !== '') {
        const shortFallSplitStrs = shortfallAmountStr.split('£');
        if (shortFallSplitStrs[1]) {
          shortfallAmount = shortFallSplitStrs[1].replace(/(.+),(.+)/g, '$1');
        }
      }
      const invoicePrefix = invoiceNo.match(/^\D+/)[0];

      const consultantResponse = await requests.get(`${API_ROUTES.SEARCH}?q=${invoicePrefix}&exact=1&restrict=C`);
      const consultant =
          consultantResponse?.resultCnt?.consultantIds === 1
              ? {
                ...consultantResponse?.results?.consultantIds[0],
                '@id': '/consultants/' + consultantResponse?.results?.consultantIds[0].id,
              }
              : null;

      const invoiceResponse = await requests.get(`${API_ROUTES.SEARCH}?q=${invoiceNo}&exact=1&restrict=I`);
      const existingInvoiceId = invoiceResponse?.resultCnt?.invoiceIds === 1 ? invoiceResponse?.results?.invoiceIds[0].id : false;

      this.setState((s) => {
        const newInvoiceData = {
          ...this.state.invoiceData,
          invoiceDate: isShortfall ? shortfallInvoiceDate : invoiceDate,
          invoiceNo,
          specialist,
          patientName,
          patientDOB,
          shortfallAmount,
          amountDue: isShortfall ? shortfallAmount : amountDue,
          //          address,
          invoiceAmount,
          serviceDate,
          subscriberNumber: subscriberNumber.trim() !== 'N/A' ? subscriberNumber : undefined,
          claimNumber: claimNumber.trim() !== 'N/A' ? claimNumber : undefined,
          isShortfall,
          consultant,
          existingInvoiceId,
          invoiceBatchId: invoiceBatch?.id ?? undefined,
          invoiceBatchConsultantId: invoiceBatch?.consultant?.id ?? undefined,
        };
        if (isShortfall) {
          newInvoiceData.shortfallInvoiceDate = invoiceDate;
        }
        return {
          extractedText: extractedText,
          invoiceData: newInvoiceData,
          currentStep: 2,
        };
      });
    };
    const reset = () => {
      this.setState(defaultState);
    };

    const setReviewedData = async (reviewedInvoiceData) => {
      const momentDOB = moment(reviewedInvoiceData.patientDOB, 'YYYY-MM-DD');
      const formattedDateStr = momentDOB.format('DD/MM/YYYY');
      const eighteenYearsAgo = moment().subtract(18, 'years');
      const patientIsAdult = momentDOB.isBefore(eighteenYearsAgo);
      const contactRecordType = contactRecordTypes.find((o) => o.code === (patientIsAdult ? ContactRecordTypeCodes.Patient : ContactRecordTypeCodes.Parent));
      const response = await requests.get(
          `${API_ROUTES.MATCHING_CONTACTS}?consultantId=${reviewedInvoiceData.consultant.id}&patientName=${reviewedInvoiceData.patientName}&patientDOB=${formattedDateStr}`,
      );
      const patientName = patientIsAdult ? reviewedInvoiceData.patientName : 'Parents of ' + reviewedInvoiceData.patientName;
      this.setState({
        invoiceData: {
          ...reviewedInvoiceData,
        },
        currentStep: 3,
        contacts:
            response?.contacts?.length > 0
                ? response.contacts
                : [
                  {
                    id: -1,
                    name: patientName,
                    contactRecordType,
                    notifyByMobile: true,
                    notifyByEmail: true,
                  },
                ],
      });
    };

    const createInvoiceAndContactRecords = async (invoiceData) => {
      // ensure we're not trying to notify anyone without relevant contact details
      invoiceData.c = invoiceData.c.map((c) => {
        if (!c.mobileNumber || String(c.mobileNumber).length < 1) {
          c.notifyByMobile = false;
        }
        if (!c.email || String(c.email).length < 1) {
          c.notifyByEmail = false;
        }
        return c;
      });

      // if there are no contacts with notifications on - set the invoice to isMissingContactDetails = true;
      invoiceData.i.isMissingContactDetails = !invoiceData.c.some((c) => {
        return c.notifyByEmail || c.notifyByMobile;
      });
      try {
        const response = await requests.postWithFile(`${API_ROUTES.INVOICE_CREATE_FROM_PDF}`, invoiceData, true, this.state.rawFile);
        const { code, invoice } = JSON.parse(response);


        if (Number(code) === 201) {
          addToast(
              'Invoice created',
              true,
              false,
              <Link to={INVOICES.SINGLE.replace(':id', invoice.id)} className="btn btn-sm btn-light ml-2">
                View {invoice.invoiceNo}
              </Link>,
          );
        } else if (Number(code) === 200) {
          addToast(
              'Invoice updated',
              true,
              false,
              <Link to={INVOICES.SINGLE.replace(':id', invoice.id)} className="btn btn-sm btn-light ml-2">
                View {invoice.invoiceNo}
              </Link>,
          );
        }

        if (invoiceBatch) {
          this.props.invoiceBatchSingleFetch(invoiceBatch.id);
        }
        reset();
      } catch (err) {

        addToast('Problem creating invoice - please start over and try again', false, true);
        if (err.status === 400) {
          console.error('Handling 400 error:', err.response?.body || err.message);
        } else {
          console.error('Other error:', err);
        }
      }
    };

    return (
        <div className={isEmbed ? 'embedded-cards' : ''}>
          <Row>
            <Col>
              {this.state.currentStep === 1 && <Step1Card setExtractedText={setExtractedText} setFile={setFile} setRawFile={setRawFile}/>}
              {this.state.currentStep === 2 && (
                  <Step2Card
                      debug={debug}
                      extractedText={this.state.extractedText}
                      invoiceData={this.state.invoiceData}
                      setReviewedData={setReviewedData}
                      reset={reset}
                      file={this.state.file}>
                    {this.state.invoiceData?.existingInvoiceId !== false && (
                        <Alert variant={'info text-center'}>
                          {/*This invoice already exists*/}
                          <Link to={INVOICES.SINGLE.replace(':id', this.state.invoiceData?.existingInvoiceId)} className={'btn btn-info ml-2'}>
                            View existing Invoice
                          </Link>
                        </Alert>
                    )}
                  </Step2Card>
              )}
              {this.state.currentStep === 3 && (
                  <Step3Card
                      contacts={this.state.contacts}
                      debug={debug}
                      invoiceData={this.state.invoiceData}
                      debugData={this.state}
                      reset={reset}
                      setIsEditingContact={setIsEditingContact}
                      addNewContact={(newContact) => {
                        this.setState({ ...this.state, contacts: [...this.state.contacts, newContact] });
                        return true;
                      }}
                      updateContact={(updatedContact, origContact) => {
                        const contacts = [...this.state.contacts];
                        const i = contacts.findIndex((v) => v.name === origContact.name);
                        contacts.splice(i, 1, updatedContact);
                        this.setState({ ...this.state, contacts });
                        return true;
                      }}
                      removeContact={(contactToRemove) => {
                        const contacts = [...this.state.contacts];
                        const i = contacts.findIndex((v) => v.name === contactToRemove.name);
                        contacts.splice(i, 1);
                        this.setState({ ...this.state, contacts });
                        return true;
                      }}
                      createInvoiceAndContactRecords={createInvoiceAndContactRecords}
                  />
              )}
            </Col>
          </Row>
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceParse);
