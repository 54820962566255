import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import MASpinner from '../../common/MASpinner';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { invoiceBatchNoteAdd } from '../../../lib/redux/actions/invoiceBatchNote';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import InvoiceBatchRelatedUserTypeahead from '../../fields/InvoiceBatchRelatedUserTypeahead';
import { isChaserOutsourcer, isClient, isOutsourcer, isSeniorStaff, isStaff } from '../../../lib/helper/authorisation';
import { INVOICE_BATCH_NOTE_PREFILL, NOTE_TYPES } from '../../../lib/helper/general';
import { invoiceBatchSingleFetch } from '../../../lib/redux/actions/invoiceBatch';
import { siteSettingsListFetch, siteSettingsListUnload } from '../../../lib/redux/actions/siteSettings';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchNote,
  ...state.siteSettings,
});

const mapDispatchToProps = {
  invoiceBatchNoteAdd,
  invoiceBatchSingleFetch,
  siteSettingsListFetch,
  siteSettingsListUnload
};

class CreateNoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsersToNotify: [],
      clearTypeaheads: false,
      noteTypeCode: NOTE_TYPES.GENERAL,
      prefillNoteOptions: []
    };
  }

  componentDidMount() {
    if (!isClient()) {
      this.props.siteSettingsListFetch(1, { code: INVOICE_BATCH_NOTE_PREFILL });
    }
  }

  componentWillUnmount() {
    if (!isClient()) {
      this.props.siteSettingsListUnload()
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true, noteTypeCode: NOTE_TYPES.GENERAL, selectedUsersToNotify: [] };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { invoiceBatchNoteAdd, invoiceBatchSingleFetch, invoiceBatch, reset } = this.props;

    let isPrivate = values.isPublic !== undefined ? !values.isPublic : true;
    if (isClient()) {
      isPrivate = false;
    }
    if (isOutsourcer(true)) {
      isPrivate = true;
    }
    let noteType = {
      code: this.state.noteTypeCode,
    };
    const newNote = {
      isPrivate,
      note: values.note,
      noteType,
      invoiceBatch: invoiceBatch['@id'],
    };
    if (this.state.selectedUsersToNotify.length && !isClient()) {
      newNote.usersToNotify = this.state.selectedUsersToNotify.map((c) => c.id);
    }
    return invoiceBatchNoteAdd(newNote).then(() => {
      reset();
      this.clearTypeaheads();
      invoiceBatchSingleFetch(invoiceBatch.id);
    });
  }

  render() {
    const { change, handleSubmit, error, invoiceBatch, pristine, submitting, isAddingInvoiceBatchNote, siteSettings } = this.props;

    const getOptionsForPrefill = () => {
      return siteSettings?.reduce((options, siteSetting) => {
        if (siteSetting?.value) {
          const valueStr = String(siteSetting.value);
          options.push({
            text: valueStr.length > 30 ? valueStr.slice(0, 30) + "..." : valueStr,
            value: valueStr,
          });
        }
        return options;
      }, []) || [];
    };

    if (!invoiceBatch) {
      return <MASpinner />;
    }
    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-edit" />
        </div>
        <h2 className="invoice-batch-single__section-header">Leave a Note</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

          {!isClient() && getOptionsForPrefill().length > 0 && (
              <Field
                  name="prefillOptions"
                  component={renderSelect}
                  label="Prefill Message"
                  options={getOptionsForPrefill()}
                  onChange={selected => {
                    change('note', selected?.currentTarget?.value);
                  }}
              />
          )}

          <Field name="note" label="Message" id="note" rows="3" type="textarea" component={renderField} />
          {( isStaff() || isChaserOutsourcer() )&& (
            <>
              <InvoiceBatchRelatedUserTypeahead
                label="Send email copy to"
                invoiceBatch={invoiceBatch}
                name="usersToNotify"
                multiple={true}
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedUsersToNotify: selected };
                  });
                }}
              />
              <Field name="isPublic" label="Add a Public note?" id="is-public" component={renderCheckbox} />
              <br />
            </>
          )}
          {invoiceBatch.isEmbassy && (
            <>
              <Form.Group controlId="noteType">
                {isSeniorStaff() && (
                  <Fragment>
                    <div className="mb-3">
                      <Field
                        value={NOTE_TYPES.GENERAL}
                        type="radio"
                        name="noteType"
                        label="General Note"
                        id="generalNote"
                        component={renderCheckbox}
                        checked={this.state.noteTypeCode === NOTE_TYPES.GENERAL}
                        onChange={(e) => this.setState({ noteTypeCode: e.currentTarget.value })}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        value={NOTE_TYPES.CHASER}
                        type="radio"
                        name="noteType"
                        label="Embassy Chaser Note?"
                        id="embassyChaserNote"
                        component={renderCheckbox}
                        checked={this.state.noteTypeCode === NOTE_TYPES.CHASER}
                        onChange={(e) => this.setState({ noteTypeCode: e.currentTarget.value })}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        value={NOTE_TYPES.CASE_CLOSED}
                        type="radio"
                        name="noteType"
                        label="Embassy Case Closed Note"
                        id="embassyCaseClosedNote"
                        component={renderCheckbox}
                        checked={this.state.noteTypeCode === NOTE_TYPES.CASE_CLOSED}
                        onChange={(e) => this.setState({ noteTypeCode: e.currentTarget.value })}
                      />
                    </div>
                  </Fragment>
                )}
              </Form.Group>
            </>
          )}
          {isAddingInvoiceBatchNote ? (
            <MASpinner />
          ) : (
            <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block">
              Save Note
            </button>
          )}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'InvoiceBatchCreateNoteForm',
})(connect(mapStateToProps, mapDispatchToProps)(CreateNoteForm));
