import React, {useCallback, useState} from 'react';

import Card from 'react-bootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import type {ContactRecord, ContactRecordType, Invoice, InvoiceNotification, PaymentRequest} from '../../apiTypes';
import {invoiceAmountFormatter, niceDateFormatter, niceDateTimeFormatter, userNameFormatter} from '../../lib/helper/formatting';
import InvoicePDFFileDownloadButton from '../../components/invoice/InvoicePDFFileDownloadButton';
import {ManageInvoiceContacts} from '../InvoiceParse/components/ManageInvoiceContacts';
import Alert from 'react-bootstrap/Alert';
import InvoiceAssignUserForm from '../../components/forms/Invoice/InvoiceAssignUserForm';
import InvoiceEscalationForm from '../../components/forms/Invoice/InvoiceEscalationForm';
import InvoiceAuditLog from '../../components/common/InvoiceAuditLog';
import {useSelector} from 'react-redux';
import InvoiceMissingDetailsForm from '../../components/forms/Invoice/InvoiceMissingDetailsForm';
import {canUploadInvoices, isAdmin, isChaserOutsourcer, isChaserOutsourcerPlus, isStaff} from '../../lib/helper/authorisation';
import {Link} from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import InvoiceAdminActionsForm from '../../components/forms/Invoice/InvoiceAdminActionsForm';
import ManualInvoiceNotificationForm from '../../components/forms/Invoice/ManualInvoiceNotificationForm';
import InvoicePDFReplaceFileButton from '../../components/invoice/InvoicePDFReplaceFileButton';
import MASpinner from '../../components/common/MASpinner';
import InvoiceManualPaymentForm from "../../components/forms/Invoice/InvoiceManualPaymentForm";
import ManualPhoneCallForm from "../../components/forms/Invoice/ManualPhoneCallForm";
import UnlinkInvoiceFromBatchButton from "../../components/invoice/UnlinkInvoiceFromBatchButton";
import {requests} from "../../lib/helper/agent";
import * as API_ROUTES from '../../lib/routing/api';
import EmailPaymentReceiptForm from "../../components/invoice/EmailPaymentReceiptForm";

interface Props {
    invoice: Invoice;
    isUpdatingInvoice?: boolean;
    addContactRecord: (contactRecord: ContactRecord) => boolean;
    removeContactRecord: (contactRecordToRemove: ContactRecord) => boolean;
    updateContactRecord: (contactRecord: ContactRecord, id: number) => boolean;
}

const InvoiceSingle = ({invoice, addContactRecord, removeContactRecord, updateContactRecord, isUpdatingInvoice = false}: Props) => {
    const [, setContactsUnderEdit] = useState(false);
    const [selectedPaymentRequestId, setSelectedPaymentRequestId] = useState<Number | null>(null);
    const [, setPaymentRequest] = useState<any | null>(null);
    const contactRecordTypes = useSelector((state: any) => state.contactRecordTypes.contactRecordTypes);

    const generatePaymentReceiptPdf = useCallback((paymentReq: PaymentRequest, invoice: Invoice) => {
        requests.getFile(
                API_ROUTES.PAYMENT_RECEIPT_EXPORT.replace(':paymentRequestId', String(paymentReq?.id)),
                {},
                `payment-receipt-${invoice?.invoiceNo}-${niceDateTimeFormatter(paymentReq?.createdAt, 'YYYYMMDDHHmm')}.pdf`,
                true,
                true
        );
    }, []);

    const onPaymentRequestSendSuccess = useCallback(() => {
        setPaymentRequest(null);
        setSelectedPaymentRequestId(null);
    }, []);

    if (!invoice) {
        return <MASpinner/>;
    }
    const addNewContact = (newContact: ContactRecord) => {
        addContactRecord({
            invoice: invoice['@id'],
            ...newContact,
            contactRecordType: contactRecordTypes.find((o: ContactRecordType) => o.code === newContact.contactRecordType!.code)['@id'], // switch from object to iri
        });
        // invoice.contactRecords.push(newContact);
        return true;
    };
    const updateContact = (updatedContact: ContactRecord, origContact: ContactRecord) => {
        updateContactRecord(
                {
                    ...updatedContact,
                    contactRecordType: contactRecordTypes.find((o: ContactRecordType) => o.code === updatedContact.contactRecordType!.code)['@id'], // switch from object to iri
                },
                updatedContact.id!,
        );
        // const contacts = [...invoice.contactRecords];
        // const i = contacts.findIndex((v) => v.name === origContact.name);
        // contacts.splice(i, 1, updatedContact);
        // invoice.contactRecords = contacts;
        return true;
    };

    const removeContact = (contactToRemove: ContactRecord) => {
        // console.log('InvoiceSingle.removeContact');
        removeContactRecord({
            invoice: invoice['@id'],
            ...contactToRemove,
        });
        return true;
    };

    const displayPaymentReceiptRow = (r: any) => {
        if (selectedPaymentRequestId !== r.id) {
            return null
        }

        return (
                <tr>
                    <td colSpan={7} className={'flex-grow-1'}>
                        <EmailPaymentReceiptForm
                                cancelSend={onPaymentRequestSendSuccess}
                                form={`EmailPaymentReceiptForm-${r?.id}`}
                                paymentRequest={r}
                                onPaymentRequestSendSuccess={onPaymentRequestSendSuccess}
                        />
                    </td>
                </tr>
        );
    };

    return (
            <div>
                <Row>
                    <Col>
                        <Card className="mb-4">
                            <Card.Header className={'card-header--invoice-batch-single'}>
                                <Row className={'h5'}>
                                    <Col>
                                        <small className={'font-weight-normal'}>Invoice:</small> {invoice.invoiceNo}
                                        {invoice?.invoiceBatch?.id && (
                                                <strong>
                                                    <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', String(invoice.invoiceBatch.id))} className="btn btn-sm btn-outline-primary mx-2">
                                                        Batch {invoice.invoiceBatch.id}
                                                    </Link>
                                                    {invoice.isVoid ? <UnlinkInvoiceFromBatchButton invoice={invoice}/> : null}
                                                </strong>
                                        )}
                                        {invoice.shortfallInvoiceDate && <span className="ml-2 text-uppercase badge badge-pill badge-info">Shortfall</span>}
                                    </Col>
                                    <Col>
                                        <small className={'font-weight-normal'}>{invoice.shortfallInvoiceDate ? 'Shortfall' : 'Invoice'} Date:</small>{' '}
                                        {niceDateFormatter(invoice.invoiceDate)}
                                    </Col>
                                    <Col>
                                        <small className={'font-weight-normal'}>Consultant:</small> {invoice.consultant.name}
                                    </Col>
                                    <Col>
                                        <small className={'font-weight-normal'}>O/S:</small> {invoiceAmountFormatter(invoice.outstandingBalance, '£')}
                                        {invoice.isVoid && <span className="ml-2 text-uppercase badge badge-pill badge-dark">VOID</span>}
                                        {invoice.isPaid && !invoice.isVoid && <span className="ml-2 text-uppercase badge badge-pill badge-primary">PAID</span>}
                                        {invoice.isWrittenOff && <span className="ml-2 text-uppercase badge badge-pill badge-info">Written Off</span>}
                                    </Col>
                                </Row>
                                <Row className={'h6'}>
                                    <Col>
                                        <small className={'font-weight-normal'}>Patient:</small> {invoice.patientLastName + ', ' + invoice.patientFirstName}{' '}
                                        <small className={'font-weight-normal'}>/ DOB:</small> {niceDateFormatter(invoice.dateOfBirth)}
                                    </Col>
                                    {invoice.shortfallInvoiceDate ? (
                                            <Col>
                                                <small className={'font-weight-normal'}>Original Invoice Date:</small> {niceDateFormatter(invoice.shortfallInvoiceDate)}
                                            </Col>
                                    ) : (
                                            <Col></Col>
                                    )}
                                    <Col></Col>
                                    <Col>
                                        {invoice.isEscalated && (
                                                <span className="ml-2 text-uppercase badge badge-pill badge-danger">
                      <i className="fas fa-exclamation-circle" title="Escalated"/>
                      <span className="ml-1 d-none d-sm-inline">Escalated</span>
                    </span>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} xl={3}>
                                        <Alert variant={'gray text-dark'}>
                                            <InvoiceAssignUserForm invoice={invoice}/>
                                            <hr/>
                                            <InvoiceEscalationForm invoice={invoice}/>
                                            <hr/>
                                            <InvoiceMissingDetailsForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice}/>
                                            <hr/>
                                            <Row className={'text-center'}>
                                                <Col className={'col-12'}>
                                                    <InvoicePDFFileDownloadButton invoice={invoice}/>
                                                </Col>
                                                {(isStaff() || canUploadInvoices()) && (
                                                        <Col className={'col-12'}>
                                                            <InvoicePDFReplaceFileButton invoice={invoice}/>
                                                        </Col>
                                                )}
                                            </Row>
                                        </Alert>
                                        {(isStaff() || isChaserOutsourcer()) && invoice && (
                                                <Row>
                                                    <Col>

                                                        {!!invoice?.invoicePDFFile?.id && invoice.contactRecords.filter((c) => c.notifyByEmail || c.notifyByMobile).length > 0 && (
                                                                <Alert variant={'gray text-dark'}>
                                                                    <ManualInvoiceNotificationForm invoice={invoice}/>
                                                                </Alert>
                                                        )}
                                                        <Alert variant={'gray text-dark'}>
                                                            <h5>Manual Phone Call</h5>
                                                            <ManualPhoneCallForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice}/>
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                        )}
                                        {(isStaff() || isChaserOutsourcerPlus(true)) && invoice && (
                                                <Row>
                                                    <Col>
                                                        <Alert variant={'gray text-dark'}>
                                                            <h5>Invoice Actions</h5>
                                                            {invoice.outstandingBalance > 0 && !invoice.isPaid && !invoice.isWrittenOff && (<>
                                                                <InvoiceManualPaymentForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice}/>
                                                                <hr/>
                                                            </>)}
                                                            <InvoiceAdminActionsForm invoice={invoice}/>
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                        )}
                                    </Col>
                                    <Col xl={9}>
                                        <ManageInvoiceContacts
                                                setContactsUnderEdit={setContactsUnderEdit}
                                                contacts={invoice.contactRecords}
                                                updateContact={updateContact}
                                                addNewContact={addNewContact}
                                                removeContact={removeContact}
                                                existingInvoice={true}
                                        />
                                        <hr/>
                                        {invoice?.paymentRequests && invoice?.paymentRequests?.length > 0 && (
                                                <>
                                                    <h5>Payment Activity</h5>

                                                    <Alert variant={'success'}>
                                                        <Row className={'d-flex flex-grow-1'}>
                                                                    <table className={'table-sm flex-grow-1'}>
                                                                        <tbody>
                                                                        {invoice?.paymentRequests?.map((r) => (
                                                                                <React.Fragment key={r.id}>
                                                                                    <tr>
                                                                                        <th>{r.paymentProvider === 'manual' ? 'Manual Payment' : 'Payment Request'}</th>
                                                                                        <td className={'px-4'}>{niceDateTimeFormatter(r.createdAt)}</td>
                                                                                        <td className={'px-4'}>
                                                                                            {r.paymentProvider !== 'manual' ? 'Ref: ' : ''}{r.transactionReference} {r.paymentProvider !== 'manual' ? `(${r.paymentProvider})` : ''}
                                                                                        </td>
                                                                                        <td className={'px-4'}>Amount: {invoiceAmountFormatter(r.amount, '£')}</td>
                                                                                        <td>{r.paymentProvider !== 'manual' ? `Status: ${r.status.name}` : ''}</td>
                                                                                        <td>{r.paymentProvider !== 'manual' ? `Transaction ID: ${r.transactionId}` : ''}</td>
                                                                                        <td className="d-flex" style={{'justifyContent': 'end'}}>
                                                                                            <span title={`Export payment receipt for ${r.transactionReference}`} onClick={() => generatePaymentReceiptPdf(r, invoice)}>
                                                                                                <i className="p-2 fas fa-file-download pointer"/>
                                                                                            </span>
                                                                                            <span title={`Email payment receipt for ${r.transactionReference}`} onClick={() => {
                                                                                                const newId = selectedPaymentRequestId === r.id ? null : r.id;
                                                                                                setSelectedPaymentRequestId(newId);
                                                                                                setPaymentRequest(newId ? r : null);
                                                                                            }}>
                                                                                                <i className="p-2 mx-2 fas fa-envelope pointer"/>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {displayPaymentReceiptRow(r)}
                                                                                </React.Fragment>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                        </Row>
                                                    </Alert>
                                                </>
                                        )}

                                        <h5>
                                            Notification Activity
                                            {invoice.disableChasing && <i className="ml-2 fas fa-pause-circle text-danger" title="Notifications Paused"/>}
                                            {invoice.hasOwnProperty('shouldSendNotifications') && invoice.shouldSendNotifications === true ?
                                                    <i className="ml-2 fas fa-play-circle text-primary" title="Notifications should currently send"/>
                                                    :
                                                    <p className={"ml-2 badge badge-pill badge-danger text-xs"}><i className="mr-2 fas fa-pause-circle text-white" title="Notifications should not send"/>Notifications currently wont send: {invoice.shouldSendNotifications}</p>
                                            }
                                        </h5>
                                        {/*<Row>*/}
                                        {/*  <Col>*/}
                                        {/*    <Card className={'mb-2 no-shadow card-tight'}>*/}
                                        {/*      <Card.Header>*/}
                                        {/*        <span className={'h6'}>Invoice Created</span>*/}
                                        {/*      </Card.Header>*/}
                                        {/*      <Card.Body>*/}
                                        {/*        <div>*/}
                                        {/*          Invoice created {niceDateTimeFormatter(invoice.createdAt)}{' '}*/}
                                        {/*          {invoice.createdBy ? <>by {userNameFormatter(invoice.createdBy)}</> : null}*/}
                                        {/*        </div>*/}
                                        {/*      </Card.Body>*/}
                                        {/*    </Card>*/}
                                        {/*  </Col>*/}
                                        {/*</Row>*/}
                                        <Alert variant={'info'}>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <table className={'table-sm'}>
                                                            <tbody>
                                                            <tr>
                                                                <th>Record Created</th>
                                                                <td className={'px-4'}>{niceDateTimeFormatter(invoice.createdAt)}</td>
                                                                <td className={'px-4'}>{invoice.createdBy ? <>by {userNameFormatter(invoice.createdBy)}</> : null}</td>
                                                                <td colSpan={2}></td>
                                                            </tr>
                                                            {invoice?.invoiceNotifications?.map((n: InvoiceNotification) =>
                                                                    n.events.map((e, i) => (
                                                                            <tr key={e.id} data-notif-id={n.id} data-event-id={e.id}>
                                                                                <th>{i === 0 ? n.activityName : ''}</th>
                                                                                <td className={'px-4'}>{String(e?.sentAt) !== "" && String(e?.sentAt) !== "undefined" ? niceDateTimeFormatter(e.sentAt) : niceDateTimeFormatter(e.createdAt)}</td>
                                                                                <td className={'px-4'}>
                                                                                    {e.type.name} {e.type.name === 'Phone Call' ? 'made' : `sent to ${e.sentTo ? e.sentTo + ' ' : ''}${e.contactRecord?.contactRecordType?.name}`}
                                                                                </td>
                                                                                <td className={'px-4'}>Status:{' ' + String(e.status)}</td>
                                                                                <td>{e.type.name === 'Whatsapp' && e.serviceIdentifier ? (isAdmin() ? <a
                                                                                        href={`https://console.twilio.com/us1/monitor/logs/sms?pageSize=50&sid=${e.serviceIdentifier}`}
                                                                                        rel="noreferrer"
                                                                                        target={"_blank"}>SID: {e.serviceIdentifier}</a> : e.serviceIdentifier) : ''}</td>
                                                                            </tr>
                                                                    )),
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div className="crud__section mb-3">
                                            <div className="crud__section-icon">
                                                <i className="fas fa-book"/>
                                            </div>
                                            <h2 className="crud__section-header">Audit Log</h2>
                                            <InvoiceAuditLog invoice={invoice}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/*{jspp({ invoice })}*/}
            </div>
    );
};

export default InvoiceSingle;
