import {
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const validateInvoiceNumbersRequest = () => ({
  type: INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST,
});

export const validateInvoiceNumbersReceived = (invoiceNumbers) => ({
  type: INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED,
  invoiceNumbers,
});

export const validateInvoiceNumbersError = (error) => ({
  type: INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR,
  error,
});

export const resetValidateInvoiceNumbers = () => ({
  type: INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET,
});

export const validateInvoiceNumbers = (invoiceNumbers, invoiceBatchId) => {
  return (dispatch) => {
    dispatch(validateInvoiceNumbersRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_BATCH_VALIDATE_NUMBERS}`.replace(':id', invoiceBatchId), { invoiceNumbers })
      .then((response) => {
        dispatch(validateInvoiceNumbersReceived(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(validateInvoiceNumbersError(parseApiErrors(error)));
        return Promise.reject(parseApiErrors(error));
      });
  };
};

export const validateInvoiceNumbersReset = () => {
  return (dispatch) => {
    dispatch(resetValidateInvoiceNumbers());
  };
};
