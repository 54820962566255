import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { isEqual } from 'lodash';
import { Field, SubmissionError, change, untouch, reduxForm, getFormValues } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { invoiceBatchSingleUpdate, invoiceBatchSingleComplete } from '../../../lib/redux/actions/invoiceBatch';
import { addToast } from '../../../lib/redux/actions/toast';
import MASpinner from '../../common/MASpinner';
import { isSeniorStaff } from '../../../lib/helper/authorisation';
import { Alert } from 'react-bootstrap';
import CompleteButton from '../../common/CompleteButton';
import { isProcessing } from '../../../lib/helper/invoiceBatch';
import { validateInvoiceNumbers, validateInvoiceNumbersReset } from '../../../lib/redux/actions/validateInvoiceNumbers';
import * as ROUTES from '../../../lib/routing/frontend';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
  const initialValues = {};
  if (state.invoiceBatchSingle.invoiceBatch && state.invoiceBatchSingle.invoiceBatch.invoiceNumbers.length > 0) {
    state.invoiceBatchSingle.invoiceBatch.invoiceNumbers.forEach((invoiceNumber, i) => {
      initialValues[`invoiceNumber_${i}`] = invoiceNumber;
    });
  }
  return {
    isUpdatingInvoiceBatch: state.invoiceBatchSingle.isUpdatingInvoiceBatch,
    checkedInvoiceNumbers: state.validateInvoiceNumbers.checkedInvoiceNumbers,
    isValidating: state.validateInvoiceNumbers.isValidating,
    checkedInvoiceNumberError: state.validateInvoiceNumbers.error,
    initialValues,
    currentFormValues: getFormValues('InvoiceBatchInvoiceNumbersForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceBatchSingleUpdate,
  invoiceBatchSingleComplete,
  validateInvoiceNumbers,
  validateInvoiceNumbersReset,
  addToast,
};

class InvoiceBatchInvoiceNumbersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      override: false,
      rangeContainsDuplicates: false,
    };
  }

  renderInvoiceNumberFields(invoiceBatch) {
    const fieldArr = [];
    let i = 0;
    const workingNumberOfInvoiceToProcess = Number(invoiceBatch.numberOfInvoicesToProcess - invoiceBatch.invoices.length);
    const numberOfFieldsRequired =
        invoiceBatch.invoiceNumbers.length > workingNumberOfInvoiceToProcess ? invoiceBatch.invoiceNumbers.length : workingNumberOfInvoiceToProcess;

    while (i < numberOfFieldsRequired) {
      if (i % 4 === 0) {
        fieldArr.push(<div key={`space${i}`} className="w-100"/>);
      }
      fieldArr.push(
          <Col key={i} sm={3}>
            <Field
                onBlur={() => {
                  this.resetRange();
                }}
                name={`invoiceNumber_${i}`}
                label={null}
                type="text"
                groupClasses="mb-1"
                component={renderField}
            />
          </Col>,
      );
      i++;
    }
    return fieldArr;
  }

  resetRange() {
    this.setState({ rangeContainsDuplicates: false });
    this.props.dispatch(change('InvoiceBatchInvoiceNumbersForm', 'invoiceNumberRange', ''));
    this.props.dispatch(untouch('InvoiceBatchInvoiceNumbersForm', 'invoiceNumberRange'));
  }

  clearInvoiceNumbers() {
    const { invoiceBatch, invoiceBatchSingleUpdate, addToast, initialize, validateInvoiceNumbersReset } = this.props;
    validateInvoiceNumbersReset();
    return invoiceBatchSingleUpdate({ invoiceNumbers: [] }, invoiceBatch.id).then(() => {
      addToast('Invoice Numbers cleared', true);
      initialize({});
    });
  }

  completeInvoiceBatch() {
    const { invoiceBatch, invoiceBatchSingleComplete } = this.props;

    return invoiceBatchSingleComplete(invoiceBatch.id);
  }

  validateInvoiceNumbers(invoiceNumbers, invoiceBatchId) {
    const { validateInvoiceNumbers, checkedInvoiceNumberError } = this.props;

    return validateInvoiceNumbers(invoiceNumbers, invoiceBatchId).then((invoiceNumbers) => {
      if (!checkedInvoiceNumberError && typeof invoiceNumbers !== 'undefined') {
        return invoiceNumbers.some(function(invoiceNumber) {
          return invoiceNumber.hasRecords === true;
        });
      }
    }).catch((error) => {
      return Promise.reject(error);
    });
  }

  findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

  onSubmit(values) {
    const { invoiceBatch, invoiceBatchSingleUpdate, addToast, validateInvoiceNumbersReset } = this.props;
    const { override } = this.state;
    delete values.action;
    if (values.invoiceNumberRange) {
      this.setState({ rangeContainsDuplicates: false });
      const isRangeFormat = (value) => {
        if (!value) {
          return false;
        }
        if (value.indexOf('-') < 0) {
          return 'Range Format must be 100-200';
        }
        const [start, end] = [...value.split('-')];
        if (isNaN(start) || isNaN(end) || Number(start) > Number(end)) {
          return 'Numbers in range must be numeric and the first number must be lower than the second';
        }
        return false;
      };
      const rangeFormatError = isRangeFormat(values.invoiceNumberRange);
      if (rangeFormatError !== false) {
        throw new SubmissionError({ invoiceNumberRange: rangeFormatError });
      }
      let [start, end] = [...values.invoiceNumberRange.split('-')];
      const invoiceNumbers = [];
      while (Number(start) <= Number(end)) {
        invoiceNumbers.push(Number(start));
        start++;
      }
      const invoiceNosInInvoices = invoiceBatch.invoices.map(invoice => {
        const match = invoice.invoiceNo.match(/\d+/g);
        return match ? parseInt(match[0]) : null;
      }).filter(num => num !== null);
      const filteredInvoiceNumbers = invoiceNumbers.filter(num => !invoiceNosInInvoices.includes(num));
      if (filteredInvoiceNumbers.length > invoiceBatch.numberOfInvoicesToProcess) {
        if (!window.confirm('You are entering ' + filteredInvoiceNumbers.length + ' but only have ' + invoiceBatch.numberOfInvoicesToProcess + ' invoices to process. Please confirm that is correct?')) {
          return;
        }
      }
      if (filteredInvoiceNumbers.length > 60) {
        if (!window.confirm('You are entering ' + filteredInvoiceNumbers.length + ' invoice numbers. Please confirm that is correct?')) {
          return;
        }
      }

      this.validateInvoiceNumbers(filteredInvoiceNumbers, invoiceBatch.id).then((hasRecords) => {
        if (!hasRecords || override) {
          return invoiceBatchSingleUpdate({ invoiceNumbers: filteredInvoiceNumbers }, invoiceBatch.id).then(() => {
            addToast('Invoice Numbers saved', true);
            validateInvoiceNumbersReset();
            this.setState({ override: false });
          });
        } else {
          this.setState({ rangeContainsDuplicates: true });
        }
      }).catch(error => {});
    } else {
      const invoiceNumbers = Object.values(values).filter((item) => item);
      const duplicates = [...new Set(this.findDuplicates(invoiceNumbers))];

      if (duplicates.length > 0) {
        addToast('You cannot submit duplicate invoice numbers: ' + duplicates.join(), false, true);
        return;
      }

      this.validateInvoiceNumbers(
          Object.values(values).filter((item) => item),
          invoiceBatch.id,
      ).then((hasRecords) => {
        if (!hasRecords || override) {
          return invoiceBatchSingleUpdate({ invoiceNumbers }, invoiceBatch.id).then(() => {
            addToast('Invoice Numbers saved', true);
            validateInvoiceNumbersReset();
            this.setState({ override: false });
          });
        }
      }).catch(error => {});
    }
  }

  render() {
    const {
      error,
      pristine,
      invoiceBatch,
      isUpdatingInvoiceBatch,
      submitting,
      handleSubmit,
      currentFormValues,
      isValidating,
      checkedInvoiceNumbers,
      checkedInvoiceNumberError,
    } = this.props;
    if (!isProcessing(invoiceBatch)) {
      return null;
    }
    const workingNumberOfInvoiceToProcess = Number(invoiceBatch.numberOfInvoicesToProcess - invoiceBatch.invoices.length);
    const currentInvoiceNumberFields = currentFormValues ? Object.keys(currentFormValues).filter((fieldName) => /invoiceNumber_/.test(fieldName)) : {};
    const currentFilledInvoiceNumberFields = currentFormValues ? currentInvoiceNumberFields.filter((fieldName) => currentFormValues[fieldName] !== '') : [];
    const currentEnteredInvoiceNumbers = currentFilledInvoiceNumberFields.map((fieldName) => currentFormValues[fieldName]);
    const currentNumberOfEnteredInvoiceNumbers = currentEnteredInvoiceNumbers.length;
    let duplicateInvoiceNumbers = false;
    let duplicateInvoiceNumbersArr = [];
    if (!isValidating && checkedInvoiceNumbers.length > 0) {
      duplicateInvoiceNumbersArr = checkedInvoiceNumbers
          .filter((invoiceNumber) => invoiceNumber.hasRecords === true)
          .map((invoiceNumber) => {
            return invoiceNumber.invoiceNumber;
          });
      duplicateInvoiceNumbers = duplicateInvoiceNumbersArr.join();
    }
    const batchHasCorrectSavedNumberOfInvoiceNumbers = Number(invoiceBatch.invoiceNumbers.length) === workingNumberOfInvoiceToProcess;
    const batchHasCorrectFrontendNumberOfInvoiceNumbers = Number(currentNumberOfEnteredInvoiceNumbers) === workingNumberOfInvoiceToProcess;
    const frontendInvoiceNumbersMatchSaved = isEqual(invoiceBatch.invoiceNumbers, currentInvoiceNumberFields);
    const frontendStillContainsDuplicates =
        duplicateInvoiceNumbersArr.some((d) => currentEnteredInvoiceNumbers.includes(d)) || this.state.rangeContainsDuplicates;

//        console.log({
//          frontendInvoiceNumbersMatchSaved,
//          batchHasCorrectSavedNumberOfInvoiceNumbers,
//          batchHasCorrectFrontendNumberOfInvoiceNumbers,
//          duplicateInvoiceNumbers,
//          frontendStillContainsDuplicates,
//          workingNumberOfInvoiceToProcess,
//          currentInvoiceNumberFields,
//          currentFilledInvoiceNumberFields,
//          currentEnteredInvoiceNumbers,
//          currentNumberOfEnteredInvoiceNumbers
//        })

    return (
        <Row>
          <Col>
            <div className="invoice-batch-single__section mb-3">
              <div className="invoice-batch-single__section-icon">
                <i className="fas fa-edit"/>
              </div>
              <h2 className="invoice-batch-single__section-header d-flex justify-content-between">
                Add Associated Invoice Numbers
                <button onClick={this.clearInvoiceNumbers.bind(this)} type="button" className="btn btn-sm btn-outline-danger w-auto">
                  Clear all
                </button>
              </h2>

              <p>Enter the associated invoice numbers below and then click 'Save' to update the Invoice Batch.</p>
              <p>If the invoice numbers are consecutive you can use the below range entry field to fill all the fields below with one click.</p>

              {error && <div className="alert alert-danger">{error}</div>}
              {checkedInvoiceNumberError && <div className="alert alert-danger">{checkedInvoiceNumberError._error}</div>}
              <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <div className="mb-3">
                  <Form.Row className="mb-2">
                    <Col xs={10}>
                      <Field
                          name="invoiceNumberRange"
                          onChange={() => {
                            this.setState({ rangeContainsDuplicates: false });
                          }}
                          label={null}
                          placeholder="Enter a consecutive range with a hyphen e.g. 001-009"
                          type="text"
                          groupClasses="mb-0"
                          component={renderField}
                      />
                    </Col>
                    <Col>
                      <button type="submit" disabled={pristine} className="btn btn-primary btn-block">
                        Go!
                      </button>
                    </Col>
                  </Form.Row>
                </div>
                <h6>Associated Invoice Numbers</h6>
                <div>
                  <p>{invoiceBatch.invoices.length} Linked invoices: </p>
                  <ul className="list-unstyled list-inline mb-0">
                    {invoiceBatch.invoices.map((i) => (
                        <li key={i.id} className="list-inline-item">
                          <Link to={ROUTES.INVOICES.SINGLE.replace(':id', i.id)} className="btn btn-sm btn-outline-dark mr-1">
                            {i.invoiceNo}
                          </Link>
                        </li>
                    ))}
                  </ul>
                </div>
                <Form.Row className="mb-2">{this.renderInvoiceNumberFields(invoiceBatch)}</Form.Row>
                {!isSeniorStaff() && invoiceBatch.invoiceNumbers.length > workingNumberOfInvoiceToProcess && (
                    <Alert variant="warning">
                      Only Senior Staff can complete a batch when the number of invoices entered is greater than the number expected. Please save/flag as a Problem
                      and leave a note for a Senior Staff member to complete this batch.
                    </Alert>
                )}

                <p>
                  {batchHasCorrectSavedNumberOfInvoiceNumbers && batchHasCorrectFrontendNumberOfInvoiceNumbers && (
                      <i className="fas fa-check-circle text-primary"></i>
                  )}{' '}
                  {invoiceBatch.invoiceNumbers.length} / {workingNumberOfInvoiceToProcess} associated manual invoice numbers entered.
                </p>

                {batchHasCorrectSavedNumberOfInvoiceNumbers && batchHasCorrectFrontendNumberOfInvoiceNumbers && (
                    <p><i className="fas fa-check-circle text-primary"></i> {invoiceBatch.numberOfInvoicesToProcess} linked invoices + invoice numbers in total.</p>
                )}

                {duplicateInvoiceNumbers && frontendStillContainsDuplicates && (
                    <div className="alert alert-danger">
                      Invoice Number{duplicateInvoiceNumbers.toString().indexOf(',') > -1 && 's'} <strong>{duplicateInvoiceNumbers}</strong>{' '}
                      {duplicateInvoiceNumbers.toString().indexOf(',') > -1 ? 'are' : 'is'} already in use
                      {!this.state.rangeContainsDuplicates
                          ? ', do you wish to use ' + (duplicateInvoiceNumbers.toString().indexOf(',') > -1 ? 'them' : 'it') + ' anyway?'
                          : '. Please type them in individually to use them anyway.'}
                    </div>
                )}
                {duplicateInvoiceNumbers && frontendStillContainsDuplicates && !this.state.rangeContainsDuplicates && (
                    <button
                        type="submit"
                        onClick={() => {
                          this.setState({ override: true });
                        }}
                        value="override"
                        disabled={pristine || submitting || isUpdatingInvoiceBatch}
                        className="btn btn-danger mr-2">
                      Save Anyway
                    </button>
                )}

                {((duplicateInvoiceNumbers && this.state.override) ||
                    (!duplicateInvoiceNumbers && !frontendInvoiceNumbersMatchSaved) ||
                    (duplicateInvoiceNumbers && !frontendStillContainsDuplicates)) && (
                    <button type="submit" disabled={pristine || submitting || isUpdatingInvoiceBatch} className="btn btn-primary mr-2">
                      Save
                    </button>
                )}

                {batchHasCorrectSavedNumberOfInvoiceNumbers && batchHasCorrectFrontendNumberOfInvoiceNumbers && (
                    <>
                      <p className="mt-2">
                        Please check the entered associated manual invoice numbers are correct and then click 'Complete Batch' button to confirm that this Invoice
                        Batch has been processed.
                      </p>
                      <CompleteButton invoiceBatch={invoiceBatch} buttonText="Complete Batch"/>
                    </>
                )}
                {submitting && isUpdatingInvoiceBatch && <MASpinner msg="Saving"/>}
              </Form>
            </div>
          </Col>
        </Row>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
InvoiceBatchInvoiceNumbersForm = reduxForm({
  form: 'InvoiceBatchInvoiceNumbersForm',
  enableReinitialize: true,
})(InvoiceBatchInvoiceNumbersForm);

// You have to connect() to any reducers that you wish to connect to yourself
InvoiceBatchInvoiceNumbersForm = connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchInvoiceNumbersForm);

export default InvoiceBatchInvoiceNumbersForm;
