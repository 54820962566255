import React from 'react';
import clsx from 'clsx';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

export const renderField = ({
  input,
  label,
  textRight = false,
  type,
  meta: { error },
  groupClasses,
  formLabelClasses,
  horizontal = false,
  horizontalLabelWidth = 3,
  ...rest
}) => {
  const classes = clsx('form-control', {
    'is-invalid': error,
    'text-right': textRight,
  });
  return (
    <FormGroup className={groupClasses + (horizontal ? ' row' : '')}>
      {label !== null && label !== '' && (
        <FormLabel className={formLabelClasses + (horizontal ? ' col-sm-' + String(horizontalLabelWidth) + ' col-form-label' : '')}>{label}</FormLabel>
      )}
      {horizontal ? (
        <div className={'col-sm-' + String(12 - horizontalLabelWidth)}>
          {type !== 'textarea' && <FormControl {...input} {...rest} type={type} className={classes} />}
          {type === 'textarea' && <textarea {...input} {...rest} className={classes} />}
          {error && <small className="form-text text-danger">{error}</small>}
        </div>
      ) : (
        <>
          {type !== 'textarea' && <FormControl {...input} {...rest} type={type} className={classes} />}
          {type === 'textarea' && <textarea {...input} {...rest} className={classes} />}
          {error && <small className="form-text text-danger">{error}</small>}
        </>
      )}
    </FormGroup>
  );
};

export const renderCheckbox = ({
                                 input,
                                 label,
                                 type = 'switch',
                                 id,
                                 meta: { error },
                                 wrapperClassNames = null,
                                 labelLeft = false,
                                 horizontalLabelWidth = 3,
                                 horizontal = false,
                                 ...rest
                               }) => {
  return (
      <div className={wrapperClassNames}>
        {labelLeft ? (
            <>
              <FormLabel htmlFor={id} className={horizontal ? ' col-sm-' + String(horizontalLabelWidth) + ' col-form-label' : ''}>{label}</FormLabel>
              <Form.Check {...input} type={type} id={id} checked={input.value} {...rest} />
            </>
        ) : (
            <Form.Check {...input} type={type} id={id} label={label} checked={input.value} {...rest} />
        )}
        {error && <small className="form-text text-danger">{error}</small>}
      </div>
  );
};

export const renderSelect = ({ input, options, label, showEmpty = true, emptyLabel = 'Any', emptyValue = '', meta: { touched, error }, helpText = null, ...rest }) => (
  <FormGroup>
    {label !== null && label !== '' && <FormLabel>{label}</FormLabel>}
    <FormControl {...input} {...rest} as="select">
      {showEmpty && (
        <option key="-1" value={emptyValue}>
          {emptyLabel}
        </option>
      )}
      {options.map((val) => (
        <option key={val.value} value={val.value}>
          {val.text}
        </option>
      ))}
    </FormControl>
      {helpText && <small className="form-text">{helpText}</small>}
    {touched && error && <small className="form-text text-danger">{error}</small>}
  </FormGroup>
);

export const renderReactSelect = ({
  input,
  options,
  label,
  showEmpty = true,
  selectedOptions = [],
  handleSelect = () => {},
  emptyLabel = 'Any',
  meta: { touched, error },
  ...rest
}) => (
  <FormGroup>
    {label !== null && label !== '' && <FormLabel>{label}</FormLabel>}
    <Select
      styles={{ menu: (styles) => Object.assign(styles, { zIndex: 1000 }) }}
      value={selectedOptions}
      onChange={handleSelect}
      options={options}
      {...rest}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary: '#338b2b',
        },
      })}
    />
    {touched && error && <small className="form-text text-danger">{error}</small>}
  </FormGroup>
);

class DateInput extends React.PureComponent {
  state = { readOnly: false };

  render() {
    return (
      <input
        {...this.props}
        onFocus={() => this.setState({ readOnly: true })}
        onBlur={() => this.setState({ readOnly: false })}
        readOnly={this.state.readOnly}
      />
    );
  }
}

export const renderDatePicker = ({
  input,
  label,
  selected,
  required,
  handleChange,
  wrapperClassName = 'react-date-picker',
  className = 'form-control',
  handleSelect,
  clearOnClose = false,
  shouldCloseOnSelect = false,
  popperPlacement = '',
  meta: { touched, error, warning, invalid },
}) => {
  const getUtcDate = (date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return (
    <FormGroup>
      {label !== null && label !== '' && <FormLabel>{label}</FormLabel>}
      <DatePicker
        wrapperClassName={wrapperClassName}
        customInput={<DateInput />}
        className={className}
        style={{ width: '100%' }}
        {...input}
        selected={selected}
        onChange={(value) => {
          handleChange(getUtcDate(value));
          input.onChange(getUtcDate(value));
        }}
        popperPlacement={popperPlacement}
        shouldCloseOnSelect={shouldCloseOnSelect}
        onSelect={(value) => {
          handleSelect(getUtcDate(value));
        }}
        dateFormat="dd/MM/yyyy"
        //            onBlur={() => input.onBlur(input.value)}
        required={required}
        onCalendarClose={clearOnClose ? () => handleChange(null) : null}
      />
      {touched && error && <small className="form-text text-danger">{error}</small>}
      {touched && warning && <small className="form-text text-danger">{warning}</small>}
    </FormGroup>
  );
};
//
//export const renderDatePicker = ({ input, placeholder, minDate, maxDate }) => (
//    <DatePicker
//        className="plus-icon"
//        dateFormat="yyyy/MM/dd"
//        selected={input.value || null}
//        onChange={input.onChange}
//        minDate={minDate}
//        maxDate={maxDate}
//        disabledKeyboardNavigation
//        placeholderText={placeholder}
//    />
//);
